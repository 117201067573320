@mixin focused() {
    outline: 0 none;
    outline-offset: 0;
    transition: box-shadow 0.2s;
    box-shadow: var(--focus-ring);
}

@mixin focused-inset() {
    outline: 0 none;
    outline-offset: 0;
    transition: box-shadow 0.2s;
    box-shadow: inset var(--focus-ring);
}

@mixin respond-to($breakpoint) {
    @if $breakpoint == sm {
        @media (min-width: $breakpoint-sm) {
            @content;
        }
    } @else if $breakpoint == md {
        @media (min-width: $breakpoint-md) {
            @content;
        }
    } @else if $breakpoint == lg {
        @media (min-width: $breakpoint-lg) {
            @content;
        }
    } @else if $breakpoint == xl {
        @media (min-width: $breakpoint-xl) {
            @content;
        }
    }
}
